export class ElencoCoupon {
	constructor() {
		this.itemID = null;
		this.codiceCoupon = null;
		this.istanteCreazione = null;
		this.associatoA = null;
		this.utilizzoData = null;
		this.utilizzoUtente = null;
		this.sconto1_CodiceProdotto = null;
		this.sconto1_Valore = null;
		this.sconto1_Tipo = null;
		this.sconto2_CodiceProdotto = null;
		this.sconto2_Valore = null;
		this.sconto2_Tipo = null;
		this.sconto3_CodiceProdotto = null;
		this.sconto3_Valore = null;
		this.sconto3_Tipo = null;
		this.validaDa = null;
		this.validoA = null;
		this.usoEsclusivo = null;
		this.emailNotifica = null;
		this.descrizione = null;
		this.descrizione1 = null;
		this.descrizione2 = null;
		this.numeroCoupon = null;
	}

	itemID: number;
	codiceCoupon: string;
	istanteCreazione: string | null;
	associatoA: string;
	utilizzoData: string | null;
	utilizzoUtente: string;
	sconto1_CodiceProdotto: string;
	sconto1_Valore: number | null;
	sconto1_Tipo: number | null;
	sconto2_CodiceProdotto: string;
	sconto2_Valore: number | null;
	sconto2_Tipo: number | null;
	sconto3_CodiceProdotto: string;
	sconto3_Valore: number | null;
	sconto3_Tipo: number | null;
	validaDa: string | null;
	validoA: string | null;
	usoEsclusivo: number | null;
	emailNotifica: string;
	descrizione: string;
	descrizione1: string;
	descrizione2: string;
	numeroCoupon: number | null
}