// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueExtended, TextValueItem } from "../../../models/TextValueItem";
import { ElencoCoupon } from "@/models/anafrafica/elencoCoupon";
import permessi from "@/config/permessi";
import editForm from "@/components/grid/edit/editForm.vue";

const _ENDPONT = api.endpoint.COUPON.COUPON;

@Component({})
export default class elencoCouponPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.COUPON];
	}
	editItem: ElencoCoupon = new ElencoCoupon();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = false;

	tipiSconto: TextValueItem[] = [];
	pacchetti: TextValueExtended[] = [];

	numeroCoupon: TextValueItem[] = [
		{ text: "1", value: 1 },
		{ text: "5", value: 5 },
		{ text: "10", value: 10 },
		{ text: "50", value: 50 },
		{ text: "100", value: 100 }
	];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },
			{
				field: "numeroCoupon",
				title: this.$i18n.t("coupon.numeroCoupon").toString(),
				width: 150,
				headerCell: "myHeaderCellTemplate",
				hidden: true,
				editSource: this.numeroCoupon,
				editHide: !this.isNewItem,
				editType: "select"
			},
			{ field: "codiceCoupon", title: this.$i18n.t("coupon.codiceCoupon").toString(), headerCell: "myHeaderCellTemplate", editDisabled: false, editHide: false, export: true },
			{
				field: "istanteCreazione",
				title: "Istante Creazione",
				format: "{0:dd/MM/yyyy}",
				width: 100,
				hidden: true,
				type: "date",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				editHide: this.isNewItem
			},
			{ field: "associatoA", title: "AssociatoA", width: 150, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "utilizzoData",
				title: "Utilizzo Data",
				format: "{0:dd/MM/yyyy}",
				width: 100,
				hidden: true,
				type: "date",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{ field: "utilizzoUtente", title: "UtilizzoUtente", width: 100, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },

			//{ field: "sconto1_CodiceProdotto", title: this.$i18n.t("generico.codice").toString(), width: 100, hidden: false, headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "sconto1_CodiceProdotto",
				title: this.$i18n.t("generico.codiceProdotto").toString(),
				width: 100,
				headerCell: "myHeaderCellTemplate",
				export: true,
				editType: "multi-select",
				editSource: this.pacchetti,
				multiselectSplitChar: ";",
				// selectValueType: "int",
				editSourceValue: "code",
				filterType: "multiselect",
				hidden: true
			},
			{
				field: "descrizioneSconto1",
				title: this.$i18n.t("generico.codiceProdotto").toString(),
				width: 150,
				headerCell: "myHeaderCellTemplate",
				export: true,
				itemsForFilter: this.pacchetti,
				filterType: "select",
				sortable: false,
				editHide: true
			},
			/*{ field: "sconto1_DescrizioneProdotto", title: this.$i18n.t("generico.codice").toString(), width: 100,
			 headerCell: "myHeaderCellTemplate", export: true,
			},*/
			{
				field: "sconto1_Tipo",
				title: this.$i18n.t("coupon.tipo").toString(),
				width: 80,
				values: this.tipiSconto,
				sortBy: "descrizione",
				itemsForFilter: this.tipiSconto,
				filterType: "select",
				editType: "select",
				editSource: this.tipiSconto,
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "sconto1_Valore",
				title: this.$i18n.t("coupon.valore").toString(),
				width: 70,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				editType: "numeric",
				export: true,
				headerType: "numeric"
			},

			{
				field: "sconto2_CodiceProdotto",
				title: this.$i18n.t("generico.codiceProdotto").toString() + " 2",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				export: true,
				editType: "multi-select",
				editSource: this.pacchetti,
				multiselectSplitChar: ";",
				editSourceValue: "code",
				filterType: "multiselect",
				hidden: true
			},
			{
				field: "descrizioneSconto2",
				title: this.$i18n.t("generico.codiceProdotto").toString() + " 2",
				width: 150,
				headerCell: "myHeaderCellTemplate",
				export: true,
				itemsForFilter: this.pacchetti,
				filterType: "select",
				sortable: false,
				editHide: true
			},

			{
				field: "sconto2_Tipo",
				title: this.$i18n.t("coupon.tipo2").toString(),
				width: 80,
				values: this.tipiSconto,
				sortBy: "descrizione1",
				itemsForFilter: this.tipiSconto,
				filterType: "select",
				editType: "select",
				editSource: this.tipiSconto,
				headerCell: "myHeaderCellTemplate"
			},
			{ field: "sconto2_Valore", title: this.$i18n.t("coupon.valore2").toString(), width: 70, headerCell: "myHeaderCellTemplate", editType: "numeric", headerType: "numeric", },

			{
				field: "sconto3_CodiceProdotto",
				title: this.$i18n.t("generico.codiceProdotto").toString() + " 3",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				export: true,
				editType: "multi-select",
				editSource: this.pacchetti,
				multiselectSplitChar: ";",
				editSourceValue: "code",
				filterType: "multiselect",
				hidden: true
			},
			{
				field: "descrizioneSconto3",
				title: this.$i18n.t("generico.codiceProdotto").toString() + " 3",
				width: 150,
				headerCell: "myHeaderCellTemplate",
				export: true,
				itemsForFilter: this.pacchetti,
				filterType: "select",
				sortable: false,
				editHide: true
			},

			{
				field: "sconto3_Tipo",
				title: this.$i18n.t("coupon.tipo3").toString(),
				width: 80,
				values: this.tipiSconto,
				sortBy: "descrizione2",
				itemsForFilter: this.tipiSconto,
				filterType: "select",
				editType: "select",
				editSource: this.tipiSconto,
				headerCell: "myHeaderCellTemplate"
			},
			{ field: "sconto3_Valore", title: this.$i18n.t("coupon.valore3").toString(), width: 70, headerCell: "myHeaderCellTemplate", editType: "numeric", headerType: "numeric", },

			{
				field: "validaDa",
				title: this.$i18n.t("coupon.validaDa").toString(),
				format: "{0:dd/MM/yyyy}",
				width: 100,
				type: "date",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate",
				export: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "validoA",
				title: this.$i18n.t("coupon.validaA").toString(),
				format: "{0:dd/MM/yyyy}",
				width: 100,
				type: "date",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate",
				export: true,
				exportRenderFunction: this.exportRenderDate
			},

			{
				field: "usoEsclusivo",
				title: this.$i18n.t("coupon.esclusivo").toString(),
				width: 75,
				type: "boolean",
				disabled: true,
				filterType: "boolean-int",
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{ field: "emailNotifica", title: this.$i18n.t("coupon.emailNotifica").toString(), width: 150, headerCell: "myHeaderCellTemplate" },

			{
				field: "validoPerNumeroVolte",
				title: this.$i18n.t("coupon.validoPerNumeroVolte").toString(),
				width: 70,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				export: true,
				headerType: "numeric-int"
			},

			{
				field: "utilizzatoNumeroVolte",
				title: this.$i18n.t("coupon.utilizzatoNumeroVolte").toString(),
				width: 70,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				export: true,
				editDisabled: true,
				headerType: "numeric-int"
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_SCONTO).then(res => (self.tipiSconto = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI).then(function(res) {
			self.pacchetti = (res as TextValueExtended[]).map(function(obj) {
				obj.code = String(obj.value);
				return obj;
			});
			self.pacchetti.unshift({ code: "Tutti", text: "Tutti", value: null, description: "" });
		});

		var descScontoFilter = function(e) {
			if (e.filterItemSelected) {
				self.filter.filters.push({
					field: e.field,
					operator: e.filterCompareSelected === "eq" ? "contains" : "neq",
					value: e.filterItemSelected.text
				});
			}
		};

		this.customFilters["descrizioneSconto1"] = descScontoFilter;
		this.customFilters["descrizioneSconto2"] = descScontoFilter;
		this.customFilters["descrizioneSconto3"] = descScontoFilter;

		self.mapDataCallback = function (items: any[]) {
			self.itemsGrid = self.itemsGrid.map( (x: any) => { 
				if(x.usoEsclusivo === 1)
					x.usoEsclusivo = true;
				else if( x.usoEsclusivo === 0 )
					x.usoEsclusivo = false;

				return x;
			});

		}

		Promise.all([p, p2])
			.then(responses => {
				/*self.mapDataCallback = function (items: []) {
					items.map( function(x) { 
						x.sconto1_DescrizioneProdotto = "";
						var listaCodici = x.sconto1_CodiceProdotto ? x.sconto1_CodiceProdotto.split(";") : [],
							listaDescrizioni = [];

						for(var i = 0; i < listaCodici.length; i++){
							if( listaCodici[i] === "Tutti")
								listaDescrizioni.push("Tutti");
							else{
								var pacchetto = self.pacchetti.find( pac => pac.code === listaCodici[i] );
								if( pacchetto )
									listaDescrizioni.push(pacchetto.text);
							}
						}

						x.sconto1_DescrizioneProdotto = listaDescrizioni.join(";");

						return x;
					});
				}*/

				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() {}

	addNewRow() {
		let item = new ElencoCoupon();
		item.numeroCoupon = 1;

		this.onEdit(item);
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.codiceCoupon}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.coupon")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new ElencoCoupon(), item);
		self.editSubTitle = "";
		self.editTitle = item.codiceCoupon;
		self.showEditModal = true;
	}

	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;
		
		var err = (this.$refs.editForm as editForm).validate();

		if( err){
			self.$bvToast.toast(err.join("\n"), {
				variant: "danger",
				title: self.$i18n.t('msg.erroreGenerico').toString(),
				solid: true
			});
			return;
		}

		if(self.editItem.usoEsclusivo)
			self.editItem.usoEsclusivo = 1;
		else
			self.editItem.usoEsclusivo = 0;
		
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.coupon") }).toString();
		self.setGridRow<ElencoCoupon>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}
}
